<template>
    <div v-if="store_workers">
        <div class="overflow-auto">
            <table class="table table-hover text-center">
                <thead >
                    <tr>
                        <th v-for="date in range" :key="date.format('YYYY-MM-DD')">
                            {{date.format('dddd')}} ({{date | formatDate}})
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(store) in $parent.stores" :key="store.id">
                        <td v-for="(date) in range" :key="date.format('DD-MM-YYYY')">
                            <div class="card card-success" style="min-width: 5vw">
                                <div class="card-header">
                                    <div class="card-title text-left">
                                        {{store.name}} <br>
                                        <small v-if="store.id != 0">
                                            <template v-if="date.format('dddd') != 'Sonntag'">
                                                <template>
                                                    <template v-if="hasOpenSunday(store, date)">
                                                            <span class="text-danger">{{hasOpenSunday(store, date).start_time}} - {{hasOpenSunday(store, date).stop_time}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="findBusinessHours(store, date) != null">
                                                                {{findBusinessHours(store, date).open_time | toTime}} - {{findBusinessHours(store, date).close_time | toTime}}
                                                        </template>
                                                        <template v-else>
                                                            <span class="text-danger">Geschlossen</span>
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <template v-if="hasOpenSunday(store, date)">
                                                        <span class="text-danger">{{hasOpenSunday(store, date).start_time}} - {{hasOpenSunday(store, date).stop_time}}</span>
                                                </template>
                                                <template v-else>
                                                    <span class="text-danger">Geschlossen</span>
                                                </template>
                                            </template>
                                        </small>
                                    </div>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row" v-for="(worker) in getWorkersOnDay(date.format('YYYY-MM-DD'), store.id)" :key="worker.id">
                                        <template v-if="$auth.user().employee !== null">
                                            <div class="col-12 text-left" :class="worker.employee_id == $auth.user().employee.id ? 'callout callout-info' : ''">
                                                {{worker.employee.first_name}} {{worker.employee.last_name}} <br>
                                                <small>
                                                    <p class="text-xs">
                                                        {{worker.start_time | toTime}} bis {{worker.stop_time | toTime}}
                                                    </p>
                                                </small>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-12 text-left">
                                                {{worker.employee.first_name}} {{worker.employee.last_name}} <br>
                                                <small>
                                                    <p class="text-xs">
                                                        {{worker.start_time | toTime}} bis {{worker.stop_time | toTime}}
                                                    </p>
                                                </small>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="$auth.user().employee != null">
                        <td v-for="date in range" :key="date.format('YYYY-DD-MM')">
                            <template v-if="isAbsend(date)">
                                <template v-if="isAbsend(date).state.name == 'absend'">
                                    Abwesend
                                </template>
                                <template v-else-if="isAbsend(date).state.name == 'absend_morning'">
                                    Abwesend Vormittags
                                </template>
                                <template v-else-if="isAbsend(date).state.name == 'absend_afternoon'">
                                    Abwesend Nachmittags
                                </template>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'EmployeeWorkplanWeeklyView',
    props: ['selectedWeek'],
    watch: {
        selectedWeek: {
            handler() {
                this.generateWeekRange();
            },
            deep: true
        }
    },
    data() {
        return {
            range: [],
            store_workers: [],

            store_selected: null,
            date_selected: null,
            worker_selected: null,
            store_workers_index: null,
            type: '',


            workplan_weeks_id: null,
            week: [],
            employee: [],
        }
    },
    methods: {


        isAbsend(date)
        {
            if(this.$auth.user().employee != null)
            {
                if(this.employee.holidays != undefined)
                {
                    let absend = false;
                    this.employee.holidays.forEach(element => {
                        if(element.state.name == 'absend' || element.state.name == 'absend_morning' || element.state.name == 'absend_afternoon')
                        {
                            let date1 = moment(this.$parent.parseDate(element.start_date));
                            let date2 = moment(this.$parent.parseDate(element.end_date));
                            if(date.isBetween(date1, date2, 'days', []))
                            {
                                absend = element;
                            }
                        }
                    });
                    return absend;
                }
            }
        },
        getEmployee()
        {
            if(this.$auth.user().employee != null)
            {
                this.axios
                    .post("/private/workplan-weeks/employee", {
                        employee_id: this.$auth.user().employee.id
                    })
                    .then((response) => {
                        this.employee = response.data.data;
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Beim laden des Mitarbeiters ist etwas schief gelaufen'
                        })
                    })
            }
        },
        hasOpenSunday(store, date)
        {
            let open_sunday = store.open_sundays.find(element => element.date == date.format('YYYY-MM-DD'));
            if(open_sunday != undefined)
            {
                if(open_sunday.start_time != null && open_sunday.stop_time != null)
                {
                    return open_sunday;
                } else
                {
                    return null;
                }
            }
            return null;
        },
        findBusinessHours(store, date)
        {
            return store.business_hours.find(element => element.day_of_week == moment(date).isoWeekday()-1);
        },
        getStoreWorkers() {
            this.axios
                .get("/private/workplan-weeks/show", {
                    params: {
                        year: this.$parent.year,
                        week: this.$parent.selectedWeek,
                    }
                })
                .then((response) => {
                    this.store_workers = response.data.data.workers;
                    this.workplan_weeks_id = response.data.data.id;
                    this.week = response.data.data;
                    this.reset();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden des Arbeitsplan ist etwas schief gelaufen'
                    })
                })
        },
        reset() {
            this.store_selected = null;
            this.date_selected = null;
            this.worker_selected = null;
            this.type = '';
        },

        /*

            Generators

        */
        generateWeekRange()
        {
            let index = this.$parent.weeks.findIndex(element => element.weeknumber == this.selectedWeek);
            if(index != -1)
            {
                let week = this.$parent.weeks[index];
                this.range = this.$parent.createRange(week.start_date, week.end_date);
                this.getStoreWorkers();
            }
        },

        /*

            Helpers

        */

        getWorkersOnDay(date, store_id)
        {
            let workers = [];

            this.store_workers.forEach((element, index) => {
                if(element.date == date && element.store_id == store_id)
                {
                    element.store_workers_index = index;
                    workers.push(element);
                }
            })
            return workers;
        },
    },
    mounted() {
        this.generateWeekRange();
        this.getEmployee();
    }
}
</script>

<style>

</style>