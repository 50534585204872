<template>
    <div class="card card-primary">
        <div class="card-header">
            <div class="card-title">
                Arbeitsplan
            </div>
        </div>
        <div class="card-body">
            <div class="text-right pb-2">
                <button class="btn btn-primary btn-sm mr-1" @click="removeYear()">
                    - 1 Jahr
                </button>
                <button class="btn btn-primary btn-sm" @click="addYear()">
                    + 1 Jahr
                </button>
            </div>
            <div class="card card-secondary card-outline">
                <div class="card-body">
                    <div class="overflow-auto">
                        <ul class="pagination" v-if="weeks.length > 0">
                            <li class="page-item" v-for="week in weeks" :key="week.weeknumber + Math.random()*1000" :class="week.weeknumber == selectedWeek ? 'active' : ''" :ref="week.weeknumber == selectedWeek ? 'yes' : 'no'" @click="selectWeek(week.weeknumber)">
                                <a class="page-link text-center" href="#">
                                    <h3 class="page-year">{{ week.weeknumber }}</h3>
                                    <p>
                                        {{week.start_date | formatDate}} <br>
                                        bis
                                        {{week.end_date | formatDate}}
                                    </p>
                                </a>
                            </li>
                        </ul>
                        <div v-else>
                            <h3>Zurzeit gibt es keine freigegebenen Arbeitspläne</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-secondary card-outline" v-if="weeks.length > 0">
                <div class="card-body">
                    <WeeklyView :selectedWeek="selectedWeek"></WeeklyView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WeeklyView from './components/WeeklyView.vue'
import moment from 'moment'
export default {
    name: "WorkplanIndex",
    title: "Arbeitsplan",
    components: {WeeklyView},
    data() {
        return {
            stores: [],
            weeks: [],
            year: moment().format('YYYY'),
            selectedWeek: 0,

            week_data: [],
        }
    },
    methods: {
        removeYear() {
            this.year = moment(this.year).subtract(1, 'year').format('YYYY');
            this.getWeeks();
        },
        addYear() {
            this.year = moment(this.year).add(1, 'year').format('YYYY');
            this.getWeeks();
        },
        selectWeek(weeknumber)
        {
            this.selectedWeek = weeknumber;
        },
        getStores()
        {
            this.axios
                .get("/private/workplan-weeks/stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.stores.push({
                        id: 0,
                        name: 'Schule',
                        business_hours: [],
                        open_sundays: [],
                    });
                    this.$nextTick(()=>{
                        if(this.$refs.yes != undefined)
                        {
                            this.$refs.yes[0].scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
                        }
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Fehler beim laden der Filialen'
                    })
                })
        },

        /*

            Generators

        */
        generateWeeks() {
            this.weeks = [];
            this.selectedWeek = moment().isoWeek();
            let dates;
            if(this.year == '2023') dates = this.createRange(this.year + '-02-01', this.year + '-12-31');
            if(this.year == '2024') dates = this.createRange(this.year + '-01-01', this.year + '-12-25');
            dates.forEach(element => {
                let exists = this.weeks.find(item => (item.weeknumber == element.isoWeek() && item.year == element.year()))
                if(exists == null)
                {
                    let find = this.week_data.find(curr => curr.week == element.isoWeek());
                    if(find != null)
                    {
                        if(find.active == 1)
                        {
                            this.weeks.push({
                                weeknumber: element.isoWeek(),
                                start_date: element.startOf('isoWeek').format('YYYY-MM-DD'),
                                end_date: element.endOf('isoWeek').format('YYYY-MM-DD'),
                                year: element.year(),
                                active: find.active,
                            });
                        }
                    }
                }
            });
        },

        /*

            Helpers

        */
        createRange(startDate, stopDate) {
            let dates = [];
            let currentDate = moment(this.parseDate(startDate));
            while(currentDate.isSameOrBefore(this.parseDate(stopDate)))
            {
                dates.push(moment(currentDate).locale('DE'));
                currentDate = currentDate.add(1, 'days');
            }
            return dates;
        },
        parseDate(date)
        {
            let element = date.split(/[\s-:]+/);
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },
        parseTime(time)
        {
            let element = time.split(/[\s-:]+/);
            return {
                hour: parseInt(element[0]),
                minute: parseInt(element[1]),
            }
        },
        getWeeks() {
            this.axios
                .get("/private/workplan-weeks", {
                    params: {
                        filter_active: true,
                        year: this.year
                    }
                })
                .then((response) => {
                    this.week_data = response.data.data;
                    this.generateWeeks();
                    this.getStores();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Wochen ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.getWeeks();
    }
}
</script>

<style>

</style>